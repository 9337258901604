<style scoped>
.chart {height: 450px !important;padding: 16px !important;}

.maxwidth {min-width: 110px !important;}
</style>

<template>
  <div>
    <v-container fluid grid-list-xl>
      <v-layout row wrap align-start justify-start>
        <v-flex xs12 sm12 md6 lg6 xl6 class="pa-2">
          <v-card class="a-box pa-0" width="fit-content">
            <span class="grey">
              <v-tabs v-model="tab" height="36" class="horizontal-tab tabsegment" tag="button">
                  <v-tab height="28" color="#ffffff" @click="tabsChange('capitalmarket')">Capital Market
                  </v-tab>
                  <v-tab height="28" color="#ffffff" @click="tabsChange('fiiderivative')">FII Derivative Status</v-tab>
                  <!-- <v-tab height="28" color="#ffffff" @click="tabsChange('participantwiseoi')">Participant wise Oi</v-tab> -->
              </v-tabs>
            </span>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <div v-if="capitalmarket">
      <capitalmarket />
    </div>
    <div v-if="fiiderivative">
      <fiiderivative />
    </div>
  </div>
</template>
<script>

import Capitalmarket from "@/views/fiidiioptions/capitalmarket.vue";
import Fiiderivative from "@/views/fiidiioptions/fiiderivative.vue";

export default {
  components: {
    Capitalmarket,
    Fiiderivative,
    // Participantwiseoi
  },
  data() {
    return {
      capitalmarket: true, 
      fiiderivative: false,
      tab: 0
    };
  },
  methods: {
    tabreset() {
      this.capitalmarket = this.fiiderivative = false;
    },
    tabsChange(selectedTab) {
      this.tabreset()
      this[selectedTab] = true
      selectedTab == 'capitalmarket' ? this.tab = 0 : selectedTab == 'fiiderivative' ? this.tab = 1 : this.tab = 0
      this.$router.push({ path: this.$router.history.current.fullPath, query: { tab: selectedTab } }).catch(() => {})
    },
  },

  mounted() {
    var currentTab = this.$route.query.tab 
    currentTab ? this.tabsChange(currentTab) : ''
  }
};
</script>

