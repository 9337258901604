<style scoped>
.btnGreenClr {background: #00b894;}
.btnRedClr {background: #d63031}
.redclr{background: #d63031;}
.greenclr{background: #00b894;}
.dayhighClr{background: #00b894;}
.lowClr{background: #d63031;}
.greycolor{background: grey;}
.lineheight{line-height: 18px !important;}

.optionsBgClr{background:#C8E8E5}
.featuresBgClr{background:#E8EAF6}
/* .dateBgClr{background:#FFF3E0} */
.analysis-table button{min-width: 118px;font-weight: normal;border-radius: 5px;font-size: 12px;padding: 2px 6px 4px 6px;position: relative;}
 /* .sentiment .sentimentbtn{min-width: 60px;font-weight: normal;border-radius: 5px;font-size: 12px;padding: 2px 6px 4px 6px;position: relative;}  */
.analysis-table .arrow {top: -6px; font-size: 22px!important; right: 5px; padding: 0px 12px 0px 12px; vertical-align: text-top; border-radius: 4px;}
/* .analysis-table button .arrow2 {top: -6px; font-size: 22px!important; right: 4px; background-color: #00b894; padding: 0px 12px 0px 12px; vertical-align: text-top; border-radius: 4px;} */
.strike{background:#EFEFEF}
.bg-green{background-color: #00b894}
.bg-red{background-color: #d63031}
.bg-violet{background-color: #003473}
.theme--dark .btnBlueClr{background: #1a3f5a;}
.theme--dark .btnGreenClr{background: #1C3126;}
.theme--dark .btnRedClr{background: #371A1A;}
.theme--dark .btnOrangeClr{background: #847700;color: #000000 !important;}
.theme--dark .strike{background:#000000}
.theme--dark .v-data-table th{background: #1e1e1e;}
</style>

<template>
  <div>
    <v-flex xs12 class="pa-2 pt-0">
      <v-container fluid class="chartfullview">
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0 pt-0">
            <v-card class="pa-2 a-box">
              <!-- <div class="text-center mt-4 title">FII derivative stats (Values in Crores)</div> -->
              <div class="text-center mt-1 font-weight-bold openSans">FII derivative stats</div>
            <v-chart class="chartheightDerivates" autoresize :options="DERIVATIVES" theme="macarons" />
            </v-card>
          </v-flex> 
          </v-layout
      ></v-container>
    </v-flex>
     <v-flex xs12 class="pa-2 pt-0">
      <v-container fluid>
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0 pt-0">
            <v-card class="pa-2 a-box">
              <div class="text-center mt-4 title">Detailed FII derivative stats (Values in Crores)</div>
               <v-simple-table class="analysis-table sentiment f-semibold mt-4">
                 <thead><tr>
                   <th colspan="1" class="text-center optionsBgClr"></th>
                     <th colspan="6" class="text-center optionsBgClr">Options</th>
                     <th colspan="6" class="text-center featuresBgClr">Futures</th> </tr>
                 </thead>
                  <thead>
                   <tr>
                     <th class="text-left">Date</th>
                     <th class="text-right">Stock Buy</th>
                     <th class="text-right">Stock Sell</th>
                     <th class="text-right">Stock Net</th>
                     <th class="text-right">Index Buy</th>
                     <th class="text-right">Index Sell</th>
                     <th class="text-right">Index Net</th>
           
                     <th class="text-right">Stock Buy</th>
                     <th class="text-right">Stock Sell</th>
                     <th class="text-right">Stock Net</th>
                     <th class="text-right">Index Buy</th>
                     <th class="text-right">Index Sell</th>
                     <th class="text-right">Index Net</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr v-for="(item, index) in Derivativelist" :key="index">
                      <td class="text-left"><span>{{item.Date}}</span></td>
                      <td class="text-right"><span >{{item.OptionStkBuyValue}}</span></td>
                      <td class="text-right"><span >{{item.OptionStkSellValue}}</span></td>
                      <td class="text-right"><span class="" :class=" item.OptionStkNetValue >= 0 ? 'positivevalue' : 'negativevalue' ">{{item.OptionStkNetValue}}</span></td>
                      <td class="text-right"><span >{{item.OptionIdxBuyValue}}</span></td>
                      <td class="text-right"><span >{{item.OptionIdxSellValue}}</span></td>
                      <td class="text-right"><span class="" :class=" item.OptionIdxNetValue >= 0 ? 'positivevalue' : 'negativevalue' ">{{item.OptionIdxNetValue}}</span></td>
                      <td class="text-right"><span >{{item.FutureStkBuyValue}}</span></td>
                      <td class="text-right"><span >{{item.FutureStkSellValue}}</span></td>
                      <td class="text-right"><span class="" :class=" item.FutureStkNetValue >= 0 ? 'positivevalue' : 'negativevalue' ">{{item.FutureStkNetValue}}</span></td>
                      <td class="text-right"><span >{{item.FutureIdxBuyValue}}</span></td>
                      <td class="text-right"><span >{{item.FutureIdxSellValue}}</span></td>
                      <td class="text-right"><span class="" :class=" item.FutureIdxNetValue >= 0 ? 'positivevalue' : 'negativevalue' ">{{item.FutureIdxNetValue}}</span></td>
                   </tr></tbody></v-simple-table> </v-card>
                     </v-flex> 
          </v-layout
      ></v-container>
    </v-flex>
  </div>
</template>
<script>
import VChart, {  } from "vue-echarts";
import "@/plugins/echart";
import { mapState } from 'vuex';
export default {
  components: {
    VChart,
  },
  data: () =>({}),

  computed:{
    ...mapState('fiiDiiData',['Derivativelist','DERIVATIVES']) 
  },
  
  mounted(){
    this.$store.dispatch('fiiDiiData/getDerivativeList')
  }
  
};
</script>

