<style scoped>
.lineheight{line-height: 18px !important;}
.analysis-table button{min-width: 118px;font-weight: normal;border-radius: 5px;font-size: 12px;padding: 2px 6px 4px 6px;position: relative;}
.analysis-table .arrow {top: -6px; font-size: 22px!important; right: 5px; padding: 0px 12px 0px 12px; vertical-align: text-top; border-radius: 4px;}
.strike{background:#EFEFEF}
/* .bg-green{background-color: #00b894}
.bg-red{background-color: #d63031}
.bg-violet{background-color: #003473}
.theme--dark .btnBlueClr{background: #1a3f5a;}
.theme--dark .btnGreenClr{background: #1C3126;}
.theme--dark .btnRedClr{background: #371A1A;}
.theme--dark .btnOrangeClr{background: #847700;color: #000000 !important;}
.theme--dark .strike{background:#000000} */
.theme--dark .v-data-table th{background: #1e1e1e;}
.min-width-100{min-width: 100px}
</style>

<template>
  <div>
    <v-flex xs12 class="pa-2 pt-0">
      <v-container fluid class="chartfullview">
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0 pt-0">
            <v-card class="a-box pa-2">
              <div class="text-center mt-1 font-weight-bold openSans">FII/DII Capital Market Activity (Values in Crores)</div>
            <v-chart class="chartheightFiDi" autoresize :options="CAPITALMARKET " theme="macarons" />
            </v-card>
          </v-flex> 
          </v-layout
      ></v-container>
    </v-flex>
     <v-flex xs12 class="pa-0 pt-0">
      <v-container fluid>
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12 class="pa-2 pt-0">
            <v-card class="pa-2 a-box">
              <div class="text-center mt-4 title">Detailed FII/DII Capital Market Activity (Values in Crores)</div>
               <v-simple-table class="sentiment f-semibold mt-4">
      <thead>
        <tr>
          <th class="text-left">Date</th>
          <th class="text-right">FII Buy</th>
          <th class="text-right">FII Sell</th>
          <th class="text-right">FII Net</th>
          <th class="text-center">In Market</th>
          <th class="text-right">DII Net</th>
          <th class="text-right">DII Buy</th>
          <th class="text-right">DII Sell</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="(item, index) in fiiDiiList" :key="index">
          <td class="text-left">{{ item.fiDate }}</td>
          <td class="text-right">{{ item.fiBuyValue }}</td>
          <td class="text-right">{{ item.fiSellValue }}</td>
          <td class="text-right" ><span class="" :class=" item.fiNetValue >= 0 ? 'positivevalue' : 'negativevalue' ">{{ item.fiNetValue }}  </span></td>
          <td class="text-center">
            <button
              class="white--text min-width-100 font-weight-bold px-2 rounded" :class="(parseFloat(item.fiNetValue) + parseFloat(item.diNetValue)) >= 0 ? 'btnRedClr' : 'btnGreenClr' ">
              {{ (parseFloat(item.fiNetValue) + parseFloat(item.diNetValue)).toFixed(2) }}
            </button>
          </td>
          <td class="text-right">
            <span class=""
                :class=" item.diNetValue >= 0 ? 'positivevalue' : 'negativevalue' ">
              {{ item.diNetValue }} </span>
          </td>
          <td class="text-right" ><label>{{ item.diBuyValue }}</label></td>
          <td class="text-right"><label >{{ item.diSellValue }}</label></td>
        </tr>
      </tbody>
    </v-simple-table>
            </v-card>
          </v-flex> 
          </v-layout
      ></v-container>
    </v-flex>
  </div>
</template>
<script>
import VChart, {  } from "vue-echarts";
import "@/plugins/echart";
import { mapState} from "vuex"
export default {
  components: {
    VChart,
  },
  data: () =>({}),
  
  computed: { 
     ...mapState('fiiDiiData',['fiiDiiList','CAPITALMARKET'])
  },

  mounted() {
    this.$store.dispatch('fiiDiiData/getFiiDiiData')
  }
};
</script>

